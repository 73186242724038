import { ColumnSetting } from '../core/shared/table-layout/layout.model';
import { PaginationSettings } from '../core/shared/table-layout/table.model';

export const paymentHistoryTable: ColumnSetting[] = [
    {
        primaryKey: 'Date',
        header: 'Date'
    },
    {
        primaryKey: 'InvoiceGlobalReference',
        header: 'Inv.'
    },
    {
        primaryKey: 'Description',
        header: 'Note'
    },
    {
        primaryKey: 'Amount',
        header: 'Payment',
        headerClass: 'currency',
        format: 'currency'
    }
];

export const uninvoicedTransactionsTable: ColumnSetting[] = [
    {
        primaryKey: 'Date',
        header: 'Date'
    },
    {
        primaryKey: 'Description',
        header: 'Note'
    },
    {
        primaryKey: 'Amount',
        header: 'Payment',
        headerClass: 'currency',
        format: 'currency'
    }
];

export const paymentHistoryPagination: PaginationSettings = {
    id: 'p1',
    itemsPerPage: 9,
    maxSize: 9
};

export const paymentUninvoicedPagination: PaginationSettings = {
    id: 'p5',
    itemsPerPage: 9,
    maxSize: 9
}

export const downloadsTable: ColumnSetting[] = [
    {
        primaryKey: 'Date',
        header: 'Date',
        prepend: window.innerWidth > 750 ? 'Statement dated' : ''
    },
    {
        primaryKey: 'downloadLink',
        templateDlBtn: true,
        header: ''
    }
];
export const downloadsPagination: PaginationSettings = {
    id: 'p2',
    itemsPerPage: 8,
    maxSize: 9
};

export const maintenanceTable: ColumnSetting[] = [
    {
        primaryKey: 'Date',
        header: 'Date',
        prepend: window.innerWidth > 750 ? 'Invoice dated' : ''
    },
    {
        primaryKey: 'downloadLink',
        templateDlBtn: true,
        header: ''
    }
];
export const maintenancePagination: PaginationSettings = {
    id: 'p3',
    itemsPerPage: 7,
    maxSize: 9
};
