import {Component, OnInit} from '@angular/core';
import {ApiService, UtilService, ArrearsModel, Settings, SettingsService, ChaseNote} from '../core';
import {ScriptLoaderService, GoogleChartPackagesHelper} from 'angular-google-charts';
import {DatePipe, CurrencyPipe} from '@angular/common';
import * as tableSettings from './tableSettings';

@Component({
    selector: 'app-arrears',
    templateUrl: './arrears.component.html',
    styleUrls: ['./arrears.component.less'],
    providers: [DatePipe, CurrencyPipe]
})
export class ArrearsComponent implements OnInit {
    model: ArrearsModel;
    settings: Settings;
    data = {};
    ready = false;
    tableSettings = tableSettings;
    showChart = false;

    constructor(
        private apiService: ApiService,
        private datePipe: DatePipe,
        private currencyPipe: CurrencyPipe,
        private settingsSvc: SettingsService,
        private utilService: UtilService,
        private loaderService: ScriptLoaderService
    ) {
    }

    ngOnInit() {
        this.settings = this.settingsSvc.get();
        this.apiService.get('rentarrears').subscribe(
            resp => {
                this.model = resp.data;

                let outstandingTotal = 0;

                this.model.Data[0].ChaseNotes.sort(this.sortChaseNotes).forEach((value) => {
                    value.Date = value.Date.substring(0, value.Date.indexOf('.'));
                    value.Date = this.datePipe.transform(value.Date, 'dd/MM/yyyy');

                    if (this.settings.productSettings.enableArrearsNotesSection) {
                        value.Note = value.Note.replace(/<p>&nbsp;<\/p>/g, '');
                    }
                });

                this.model.Data[0].RentOutstanding.forEach((value) => {
                    outstandingTotal = outstandingTotal + value.OutstandingRent;
                });

                // Set chart data
                this.loaderService.onReady.subscribe(() => {
                    const type = (GoogleChartPackagesHelper.getPackageForChartName('PieChart')).split(', ');
                    this.loaderService.onReady.subscribe(() => {
                        this.loaderService.loadChartPackages(type).subscribe(() => {
                            this.data = {
                                rentSummaryChart: {
                                    type: 'PieChart',
                                    data: [],
                                    columnNames: ['Total Received', 'Total Outstanding'],
                                    options: {
                                        width: '100%',
                                        height: this.utilService.chartMaxHeight(350),
                                        maxHeight: 350,
                                        legend: 'bottom',
                                        colors: ['#' + (this.settings.color1 || '38b192'), '#' + (this.settings.color2 || 'ff9251')],
                                        pieSliceText: 'value',
                                        chartArea: {
                                            top: 15,
                                            width: '100%',
                                            height: '85%'
                                        }
                                    },
                                    formatters: [
                                        {formatter: new google.visualization.NumberFormat({pattern: '£###,###.##'}), colIndex: 1},
                                    ]
                                },
                                rentCollected: this.currencyPipe.transform(this.model.Data[0].RentCollected || 0, '£'),
                                outstandingTotal: this.currencyPipe.transform(outstandingTotal || 0, '£'),
                                rentOutstanding: this.model.Data[0].RentOutstanding,
                                notes: this.model.Data[0].ChaseNotes
                            };
                            this.ready = true;
                            // custom animation on load
                            this.animateChart();
                        });
                    });
                });
            });
    }


    private animateChart() {
        // initial value
        let currentProgress = 0;
        const total = this.model.Data[0].RentCollected + this.model.Data[0].TotalRentArrears,
            percent = (2 / 100) * total,
            maxValue = this.model.Data[0].RentCollected;

        // start the animation loop
        const handler = setInterval(() => {
            // values increment
            currentProgress += percent;

            this.showChart = currentProgress > 0 || (total - currentProgress) > 0 ? true : false;

            // draw chart
            this.data['rentSummaryChart'].data = [
                ['Total Received', currentProgress],
                ['Total Outstanding', total - currentProgress]
            ];

            // check if we have reached the desired value
            if (currentProgress >= maxValue - percent) {
                // stop the loop
                clearInterval(handler);

                // set the correct values
                this.data['rentSummaryChart'].data = [
                    ['Total Received', this.model.Data[0].RentCollected],
                    ['Total Outstanding', this.model.Data[0].TotalRentArrears]
                ];
            }
            this.data['ready'] = true;
        }, 3);
    }

    private sortChaseNotes(a: ChaseNote, b: ChaseNote) {
        return b.Date.localeCompare(a.Date);
    }

}
