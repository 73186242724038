import { Component, Input, OnChanges } from '@angular/core';
import { ColumnSetting, ColumnMap } from './layout.model';
import { PaginationSettings } from './table.model';

@Component({
    selector: 'app-table-component',
    templateUrl: './table-layout.component.html'
})

export class TableLayoutComponent implements OnChanges {
    @Input() records: any[];
    @Input() paginationSettings: PaginationSettings;
    @Input() settings: ColumnSetting[];
    @Input() CurrentBalanceAmount: number;
    @Input() accountBalance: string;
    @Input() CurrentBalanceLabel: string;
    columnMaps: ColumnMap[];
    header = true;
    pagination = {
        p1: 1,
        p2: 1,
        p3: 1,
        p4: 1,
        p5: 1
    };

    ngOnChanges() {
        if (this.settings) {
            this.columnMaps = this.settings
                .map(col => {
                    this.header = col.header === '' ? false : true;
                    return new ColumnMap(col);
                });
        } else {
            this.columnMaps = Object.keys(this.records[0]).map(key => {
                return new ColumnMap({ primaryKey: key });
            });
        }
    }

    changePage(page: number, id: any) {
        this.pagination[id] = page;
    }
}
