import { Component, OnInit } from '@angular/core';
import { UtilService, DataSummary, TenancyModel, PhotoModel, Settings, SettingsService, PropertyService } from '../core';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
    model: DataSummary;
    settings: Settings;
    data = {};
    ready = false;
    devVersion = true;

    constructor(
        private utilService: UtilService,
        private propertyService: PropertyService,
        private datePipe: DatePipe,
        private settingsSvc: SettingsService,
        private _route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.devVersion = !environment.production;
        this.settings = this.settingsSvc.get();

        if (this._route.snapshot.data[0].data.hasOwnProperty('Data')) {
            this.model = this._route.snapshot.data[0].data;

            const promises = this.model.Tenancies.map((tenancy) => {
                return this.propertyService.getTenancy(
                    { tenancyID: tenancy.ID },
                    { cacheKey: 'tenancy-' + tenancy.ID }
                );
            });

            this.buildData(promises, this.model);
        }
    }


    buildData(promises, resp) {
        if (promises.length === 0) {
            this.ready = true;
            return;
        }

        forkJoin(promises)
            .subscribe(allTenancies => {
                let maintenanceJobs = 0;

                // Loop through the summary tenancies.
                resp.Tenancies.forEach((item: TenancyModel, i) => {
                    this.getPropertyPhoto(item);

                    this.extendItem(item, allTenancies[i]['data']);

                    maintenanceJobs += item.MaintenanceJobs;
                });

                this.data = {
                    data: this.model.Data,
                    maintenanceJobs: maintenanceJobs,
                    tenancies: resp.Tenancies
                };
                this.ready = true;
            });
    }

    private extendItem(item: TenancyModel, tenancy) {
        item.DescLim = item.Description.length <= 100 && item.Description ||
            item.Description.slice(0, 100) + '...';
        item.Beds = tenancy.Beds;
        item.Rent = tenancy.Rent;
        item.Bond = tenancy.Bond;
        item.StartDate = (tenancy.StartDate as any) instanceof Date && this.datePipe.transform(tenancy.StartDate, 'dd/MM/yy');
        item.ActualEndDate = this.datePipe.transform(tenancy.ActualEndDate, 'dd/MM/yy');
    }

    private getPropertyPhoto(item) {
        this.propertyService.getPropertyPhotos(
            item.TenancyProperty,
            {
                offset: '0',
                count: '1'
            },
            {
                ctrlType: 'property',
                cacheKey: 'prop-img-' + item.ID,
                runInBg: true
            }
        ).subscribe((respPhoto: PhotoModel) => {
            const data = respPhoto.data.Data;
            item.imageUrl = data[0] ? this.utilService.buildUrl(
                { photoID: data[0].OID },
                'photo/download',
                'property'
            ) : '';
        });
    }

}

