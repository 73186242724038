import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, PropertyService, UtilService, DataSummary, Settings, SettingsService } from '../core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { DatePipe } from '@angular/common';
import * as tableSettings from './tableSettings';

interface DocumentItem {
    note: string;
    url: string | null;
}

@Component({
    selector: 'app-property',
    templateUrl: './property.component.html',
    styleUrls: ['./property.component.less'],
    providers: [DatePipe]
})

export class PropertyComponent implements OnInit {
    routeParams;
    data: any = {};
    ready = false;
    floorPlanUrl;
    documents: DocumentItem[] = [];
    inventories: DocumentItem[] = [];
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
    tableSettings = tableSettings;
    settings: Settings;

    constructor(
        private route: ActivatedRoute,
        private apiService: ApiService,
        private propertyService: PropertyService,
        private utilService: UtilService,
        private datePipe: DatePipe,
        private settingsSvc: SettingsService
    ) { }

    ngOnInit() {
        this.settings = this.settingsSvc.get();
        this.galleryOptions = [
            {
                width: '100%',
                height: '400px',
                thumbnailsColumns: 4,
                thumbnails: false,
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '500px',
                imagePercent: 80,
            },
            // max-width 400
            {
                height: '300px',
                breakpoint: 400,
                preview: true
            }
        ];


        this.route.params.subscribe(data => {
            this.routeParams = {
                tenancyID: data.id
            };

            // Tenancy
            this.propertyService.getTenancy(
                this.routeParams,
                { cacheKey: 'tenancy-' + this.routeParams.tenancyID }
            ).subscribe((tenancyResp) => {
                tenancyResp = tenancyResp.data;

                // Summary - Get property description
                const summary: DataSummary = this.route.snapshot.data[0].data;
                summary.Tenancies.filter((value) => {
                    return value.ID === this.routeParams.tenancyID && (tenancyResp.DescLim = value.Description) || 0;
                });

                // Filter tenancy fields
                tenancyResp.StartDate = this.datePipe.transform(tenancyResp.StartDate, 'dd/MM/yyyy');
                tenancyResp.ActualEndDate = this.datePipe.transform(tenancyResp.ActualEndDate, 'dd/MM/yyyy');

                // Sort and filter tenancy certificates
                tenancyResp.Certificates.sort((a, b) => {
                    return (new Date(b.Due) as any) - (new Date(a.Due) as any);
                });

                tenancyResp.Certificates.forEach((value) => {
                    value.Due = this.datePipe.transform(value.Due, 'dd/MM/yyyy');
                    value.url = value.Files[0] && this.utilService.buildUrl({
                        ID: value.Files[0].ID
                    }, 'document') || null;
                });

                this.getPropertyImages(tenancyResp);

                this.data = {
                    tenancy: tenancyResp,
                };
                this.ready = true;
            });

            this.getTCFile();
        });
    }

    getTCFile() {
         if (!this.data || !this.data.tenancy || !this.data.tenancy.Documents) {
            return false;
        } else {
            this.data.tenancy.Documents.map(doc => {
                if (doc && doc.Note && ['ast', 'deposit certificate', 'landlord agreement',
                    'll toe', 'atoo', 'letter of guarantee', 'guarantor', 'renewal', 'ta', 'deed of guarantee', 'inspection report'].includes(doc.Note.toLowerCase())) {
                    const newDocument: DocumentItem = {
                        note: doc.Note.toLowerCase(),
                        url: this.utilService.buildUrl({ ID: doc.ID }, 'document')
                    };

                    this.documents.push(newDocument);
                } else if (doc && doc.Note && ['inventory', 'mid term'].includes(doc.Note.toLowerCase())) {
                    const newInventory: DocumentItem = {
                        note: doc.Note.toLowerCase(),
                        url: this.utilService.buildUrl({ ID: doc.ID }, 'document')
                    };

                    this.inventories.push(newInventory);
                }
            });
        }
    }

    getPropertyImages(tenancyResp) {
        this.propertyService.getPropertyPhotos(
            tenancyResp.TenancyProperty,
            {
                offset: '0',
                count: '99'
            },
            {
                ctrlType: 'property',
                cacheKey: 'images-' + tenancyResp.TenancyProperty,
                runInBg: true
            }
        ).subscribe((imagesResp) => {
            imagesResp.data.Data.forEach((image) => {
                // Photo type
                if (image.PhotoType === 'Photo') {
                    const url = this.utilService.buildUrl(
                        { photoID: image.OID },
                        'photo/download', 'property'
                    );

                    this.apiService.getBlob(url, null, {
                        strictUrl: true,
                        createObjectUrl: true,
                        httpCache: true
                    }).subscribe(resp => {
                        this.galleryImages.push({
                            small: resp.data,
                            medium: resp.data,
                            big: resp.data
                        });
                    });

                } else if (!this.floorPlanUrl && image.PhotoType === 'FloorPlan') {
                    // Floorplan type - first floorplan image
                    this.floorPlanUrl = this.utilService.buildUrl({
                        photoID: image.OID
                    }, 'photo/download', 'property');
                }
            });
        });

    }

}
