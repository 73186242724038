import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {ApiService, MaintenanceModel, Settings, SettingsService, UtilService} from '../core';
import * as tableSettings from './tableSettings';
import {ColumnMap, ColumnSetting} from '../core/shared/table-layout/layout.model';
import * as uuid from 'uuid';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.less']
})
export class MaintenanceComponent implements OnInit {
    @ViewChild('tagList') tagList: ElementRef;
    model: MaintenanceModel = {ready: false, data: null};
    tableSettings = tableSettings;
    settings: Settings;
    paginationSettings = tableSettings.maintenancePagination;
    collapse: any = {};
    columnMaps: ColumnMap[];
    records;
    filteredRecords;
    jobTags: any[] = [];
    isActive: boolean = true;
    pagination = {
        p1: 1,
        p2: 1,
        p3: 1,
        p4: 1,
        p5: 1
    };

    constructor(
        private settingsSvc: SettingsService,
        private apiService: ApiService) {
    }

    ngOnInit() {
        this.settings = this.settingsSvc.get();
        this.parseTableSettings();

        this.apiService.get('maintenance').subscribe(
            resp => {
                resp.data.Data.sort((a: any, b: any) => {
                    return new Date(b.Reported).getTime() - new Date(a.Reported).getTime();
                });

                this.records = resp.data.Data.map(j => {
                    j.uuid = uuid();
                    j.Status = j.ClosedDate ? 'Closed' : j.Status;
                    return j;
                });

                this.model = {
                    data: resp.data.Data,
                    ready: true
                };

                this.filteredRecords = this.records;
                this.records.forEach(job => {
                    this.jobTags.push(job.Status);
                });

                this.jobTags = UtilService.removeDuplicates(this.jobTags);
            }
        );
    }

    filterJob(tag) {
        this.filteredRecords = this.records;
        let list: any[] = this.tagList.nativeElement.children;

        for (let item of list) {
            if (item.className.includes(tag)) {
                item.className = tag + ' active';
            } else {
                item.className = item.className.replace('active', '');
            }
        }

        if (tag !== 'All') {
            this.isActive = false;
            this.filteredRecords = this.records.filter(item => item.Status === tag);
        } else {
            this.isActive = true;
        }
    }

    changePage(page: number, id: any) {
        this.pagination[id] = page;
    }

    onCollapse(uuId: string) {
        if (this.collapse[uuId] === true) {
            this.collapse[uuId] = false;
        } else {
            this.collapse[uuId] = true;
        }
    }

    private parseTableSettings() {
        const columns = this.tableSettings.maintenanceTable.filter((column: ColumnSetting) => this.isVisibleColumn(column));
        this.columnMaps = columns.map(col => new ColumnMap(col));
    }

    private isVisibleColumn(column: ColumnSetting) {
        if (!column.permissionName) {
            return true;
        }

        return !!this.settings.productSettings[column.permissionName];
    }
}
